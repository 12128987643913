import { render, staticRenderFns } from "./Logo.vue?vue&type=template&id=4b6e4a86&"
import script from "./Logo.vue?vue&type=script&lang=js&"
export * from "./Logo.vue?vue&type=script&lang=js&"
import style0 from "@/assets/styles/themes/default/logo.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports