<script>
export default {
  name: 'Logo',

  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      name: 'vitrine'
    }
  },

  computed: {
    clientAlias () {
      if (!process.env.VUE_APP_CLIENT_ALIAS) {
        return 'lite'
      }

      return process.env.VUE_APP_CLIENT_ALIAS
    }
  }
}
</script>

<template>
  <div class="logo">
    <img
      v-if="clientAlias"
      :src="`/assets/images/themes/${clientAlias}/${dark ? 'logo-dark.png' : 'logo-light.png'}`"
      class="logo-image"
    >
  </div>
</template>

<style src="@/assets/styles/themes/default/logo.css"></style>
